<template>
  <g>
    <svg:style>
      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-1 {
        fill: url(#BG-gradient);
      }

      .cls-2 {
        fill: url(#glass-pattern);
      }

      .cls-3 {
        fill: none;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#inox-gradient-2);
      }

      .cls-6 {
        fill: url(#inox-gradient-3);
      }

      .cls-7 {
        fill: url(#inox-gradient-4);
      }

      .cls-8 {
        fill: url(#inox-gradient-5);
      }

      .cls-9 {
        fill: url(#handle-gradient);
      }
    </svg:style>

    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth + 89.74"
                    :y1="doorTopHeight + 152.02"
                    :x2="doorLeftWidth + 89.74"
                    :y2="doorTopHeight + 149.89"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>

    <linearGradient id="inox-gradient-2"
                    data-name="inox"
                    :x1="doorLeftWidth + 89.59"
                    :y1="doorTopHeight + 109.45"
                    :x2="doorLeftWidth + 89.59"
                    :y2="doorTopHeight + 107.33"
                    xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-3"
                    data-name="inox"
                    :x1="doorLeftWidth + 89.74"
                    :y1="doorTopHeight + 66.88"
                    :x2="doorLeftWidth + 89.74"
                    :y2="doorTopHeight + 64.75"
                    xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-4"
                    data-name="inox"
                    :x1="doorLeftWidth + 89.59"
                    :y1="doorTopHeight + 194.6"
                    :x2="doorLeftWidth + 89.59"
                    :y2="doorTopHeight + 192.46"
                    xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-5"
                    data-name="inox"
                    :x1="doorLeftWidth + 89.74"
                    :y1="doorTopHeight + 237.17"
                    :x2="doorLeftWidth + 89.74"
                    :y2="doorTopHeight + 235.04"
                    xlink:href="#inox-gradient"/>

    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>

    <g id="L19">
      <rect filter="url(#inset-shadow)"
            id="glass"
            class="cls-2"
            :x="doorLeftWidth + 22.26"
            :y="doorTopHeight + 22.26"
            width="25.82"
            :height="inoxHeight"/>

      <line class="line"
            v-if="showImpost"
            :x1="doorLeftWidth + 59.73"
            :y1="doorTopHeight + inoxHeight + inoxOffset"
            :x2="doorLeftWidth + 59.73"
            :y2="doorTopHeight + 22.26"/>

      <rect id="inox"
            v-if="showInox"
            data-name="inox"
            class="cls-4"
            :x="doorLeftWidth + inoxL"
            :y="doorTopHeight + inoxOffset + inox4"
            :width="inoxW"
            height="2.13"/>

      <rect id="inox-2"
            v-if="showInox"
            data-name="inox"
            class="cls-5"
            :x="doorLeftWidth + inoxL"
            :y="doorTopHeight + inoxOffset + inox2"
            :width="inoxW"
            height="2.12"/>

      <rect id="inox-3"
            v-if="showInox"
            data-name="inox"
            class="cls-6"
            :x="doorLeftWidth + inoxL"
            :y="doorTopHeight + inoxOffset + inox1"
            :width="inoxW"
            height="2.13"/>

      <rect id="inox-4"
            v-if="showInox"
            data-name="inox"
            class="cls-7"
            :x="doorLeftWidth + inoxL"
            :y="doorTopHeight + inoxOffset + inox3"
            :width="inoxW"
            height="2.13"/>

      <rect id="inox-5"
            v-if="showInox"
            data-name="inox"
            class="cls-8"
            :x="doorLeftWidth + inoxL"
            :y="doorTopHeight + inoxOffset + inox5"
            :width="inoxW"
            height="2.12"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      inoxOffset: 22.26,
      leaf2Left: 7,
    }
  },
  computed: {
    inoxL() {
      return this.showBg ?
        59.58 : 48.4
    },
    showImpost() {
      return this.showBg;
    },
    inoxW() {
      return this.showBg ?
        this.doorWidth - this.inoxOffset - 59.58 :
        this.doorWidth - this.leaf2Left - 48.2
    },
    inox1() {
      return this.inoxHeight * .1666
    },
    inox2() {
      return this.inoxHeight * .3333
    },
    inox3() {
      return this.inoxHeight * .4999
    },
    inox4() {
      return this.inoxHeight * .6666
    },
    inox5() {
      return this.inoxHeight * .8333
    },
    inoxHeight() {
      return this.doorHeight - 2 * this.inoxOffset
    },
  },
}
</script>
